<template>
    <div class="container">
        <div 
            contenteditable="true" 
            id="textarea"
            @contextmenu="getSelect($event)"
            @input="editText($event)"
            @click="this.showContextMenu = false"
            data-placeholder="<p></p>"
        ></div>
        <div
            class="contextMenu" 
            :style="`position: absolute;left: ${coordinates.x}px;top: ${coordinates.y}px;`" 
            v-show="this.showContextMenu === true"
        >
            <ul class="contextMenu__list">
                <li 
                    v-for="format, idx in formats" 
                    :key="idx" 
                    class="contextMenu__list__item"
                    :style="
                        format.items.length > 1 ? 
                            `grid-template-columns: 9fr 1fr`:
                            `grid-template-columns: 1fr`
                    "
                >
                    <span 
                        class="contextMenu__list__item__name"
                        @click="addStyles(format)"
                    >
                        {{ format.name }}
                    </span>
                    <span 
                        v-if="format.items.length > 1" 
                        class="contextMenu__list__item__childrens"
                        @click="showChoice(format.items)"
                    >
                        <i class='bx bx-right-arrow-alt' ></i>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>


export default {
    data() {
        return {
            showContextMenu: false,
            coordinates: {
                x: 0,
                y: 0
            },
            formats: [
                { 
                    name: 'H', 
                    items: ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'], 
                    action: ["textSize", "hierarchy"]
                },
                { 
                    name: 'P', 
                    items: ['p'], 
                    action: ["textSize", "hierarchy"],
                },
            ],
        }
    },
    created() {
        console.log('created');
    },
    methods: {
        getSelect(event) {
            event.preventDefault();
            
            console.log('check', event)
            
            let selectedText = window.getSelection().toString().trim();
            console.log('coor', window.getSelection().getRangeAt(0).getClientRects())

            this.showContextMenu = true
            
            this.coordinates.x = event.layerX;
            this.coordinates.y = event.layerY;
        },
        editText(event) {
            console.log('test', event.target.textContent)
            console.log('test2', event)
            event.target.attributes[2].nodeValue = "test"
            console.log('test3', event.target.attributes[2].nodeValue)

            
        }
    }
}
</script>

<style lang="css">
.container{
    position: relative;
}

#textarea{
    width: 100%;
    min-height: 100px;
    font-size: 16px;
    background-color: white;
}

#textarea:focus-visible{
    outline-color: rgba(0, 0, 0, 0)
}

.contextMenu {
    background-color: rgb(250 250 250);
}

.contextMenu__list {
    padding: 1rem 0;
    margin: 0;
    box-shadow: 2px 2px 7px 0px #616161;
}

.contextMenu__list > li::marker {
    content: none;
}
.contextMenu__list > li {
    display: grid;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;

    padding: 0 0.2rem;
}

.contextMenu__list__item:hover {
    background-color: #bebebe;
    cursor: pointer ;
}

.contextMenu__list__item > .contextMenu__list__item__name {
    font-size: 18px;
    font-weight: 400;
}
</style>